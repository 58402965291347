<template>
  <div class="navbar-floating">
    <the-navbar-vertical-public :theTitle="title" display-btn-help/>
    <div class="min-h-screen flex tw-w-full">
      <div class="main-content mx-auto self-center">
        <div>
          <div class="full-page-bg-color">
            <div class="vx-row justify-center">
              <div v-if="setpassword || confirmuser || passwordrecovery" class="vx-col left my-6">
                <div v-if="setpassword || confirmuser">
                  <h4 class="mb-4">Activation de compte</h4>
                  <div class="step-list">
                    <span class="trait-vertical"></span>
                    <div class="step">
                      <span class="step-bullet">1</span>
                      <div class="step-text">
                        Félicitation ! Vous venez d’activer votre compte
                      </div>
                    </div>
                    <div class="step">
                      <span class="step-bullet">2</span>
                      <div class="step-text">
                        Saisissez votre mot de passe en respectant les
                        conditions.<br/>
                        Lien valable 24h. Au delà, vous
                        devrez faire une nouvelle demande.
                      </div>
                    </div>
                    <div class="step">
                      <span class="step-bullet">3</span>
                      <div class="step-text">
                        Après validation de votre nouveau mot de passe
                        et des conditions générales d’utilisation,
                        vous pouvez vous connecter !
                      </div>
                    </div>
                  </div>
                </div>
                <form v-if="passwordrecovery">
                  <h4 class="mb-4">Réinitialisation du mot de passe</h4>
                  <div class="step-list">
                    <span class="trait-vertical recovery"></span>
                    <div class="step">
                      <span class="step-bullet">1</span>
                      <div class="step-text">
                        Entrez votre adresse e-mail
                      </div>
                    </div>
                    <div class="step">
                      <span class="step-bullet">2</span>
                      <div class="step-text">
                        Vous recevrez un e-mail si celui-ci est rattaché
                        à un compte
                      </div>
                    </div>
                    <div class="step">
                      <span class="step-bullet">3</span>
                      <div class="step-text">
                        Cliquez sur le lien, vous pourrez choisir votre
                        nouveau mot de passe.<br/>
                        Lien valable 24h. Au delà, vous
                        devrez faire une nouvelle demande.
                      </div>
                    </div>
                    <div class="step">
                      <span class="step-bullet">4</span>
                      <div class="step-text">
                        Après validation de votre nouveau mot de passe,
                        vous pouvez vous connecter !
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="vx-col right my-6">
                <form v-if="passwordrecovery" class="px-6 py-10 relative">
                  <h5>Nouveau mot de passe</h5>
                  <div>
                    <VuePassword id="password1" v-model="password"
                                 :class="checkConditionsClass()" :disableStrength="true" class="tw-w-full mb-base" placeholder=""
                                 type="password"
                                 v-on:keyup="checkConditions()"/>
                  </div>
                  <div class="infochar">Votre mot de passe doit remplir ces 6 conditions :</div>
                  <ul class="specialchar last">
                    <li v-bind:class="[ hasMinuscule ? 'valid' : 'validation-error']"><span>a</span><br>minuscule</li>
                    <li v-bind:class="[ hasMajuscule ? 'valid' : 'validation-error']"><span>A</span><br>majuscule</li>
                    <li v-bind:class="[ hasSpecial ? 'valid' : 'validation-error']" class="special-car"><span>#</span><br>caractère spécial</li>
                    <li v-bind:class="[ hasNumber ? 'valid' : 'validation-error']"><span>1</span><br>chiffre</li>
                    <li v-bind:class="[ hasMore ? 'valid' : 'validation-error']"><span>8+</span><br>caractères</li>

                    <li class="valid" v-if="confirmationValid">
                        La confirmation correspond
                    </li>
                    <li class="validation-error" v-if="!confirmationValid">
                        La confirmation ne correspond pas
                    </li>
                  </ul>
                  <h5 class="m-t-10">Confirmez votre mot de passe</h5>
                  <div>
                    <VuePassword id="password2" v-model="password2"
                                 :class="checkConditionsClassConfirmation()" :disableStrength="true" class="tw-w-full mb-base" placeholder=""
                                 type="password"
                                 v-on:keyup="checkConditions()"/>
                  </div>
                  <div class="actions">
                    <vs-button
                      :disabled="formIsValid()"
                      class="ml-auto px-4 tw-w-full md:tw-w-auto mt-3 md:tw-mt-0 md:tw-mb-0"
                      @click="checkForm"
                    >Enregistrer
                    </vs-button>
                  </div>
                </form>
                <div v-if="setpassword" class="px-6 py-8 relative">
                  <h5>Nouveau mot de passe</h5>
                  <div>
                    <VuePassword id="password" v-model="password"
                                 :class="checkConditionsClass()" :disableStrength="true" class="tw-w-full mb-base" placeholder=""
                                 type="password"
                                 v-on:keyup="checkConditions()"/>
                  </div>
                  <p class="infochar">Votre mot de passe doit remplir ces 6 conditions :</p>
                  <ul class="specialchar last">
                    <li v-bind:class="[ hasMinuscule ? 'valid' : 'validation-error']"><span>a</span><br>minuscule</li>
                    <li v-bind:class="[ hasMajuscule ? 'valid' : 'validation-error']"><span>A</span><br>majuscule</li>
                    <li v-bind:class="[ hasSpecial ? 'valid' : 'validation-error']"><span>#</span><br>caractère spécial</li>
                    <li v-bind:class="[ hasNumber ? 'valid' : 'validation-error']"><span>1</span><br>chiffre</li>
                    <li v-bind:class="[ hasMore ? 'valid' : 'validation-error']"><span>8+</span><br>caractères</li>
                    <li class="valid" v-if="confirmationValid">
                        La confirmation correspond
                    </li>
                    <li class="validation-error" v-if="!confirmationValid">
                        La confirmation ne correspond pas
                    </li>
                  </ul>
                  <h5 class="m-t-10">Confirmez votre mot de passe</h5>
                  <div>
                    <VuePassword id="password3" v-model="password2"
                                 :class="checkConditionsClassConfirmation()" :disableStrength="true" class="tw-w-full mb-base" placeholder=""
                                 type="password"
                                 v-on:keyup="checkConditions()"/>
                  </div>
                  <div class="mt-5 cgv">
                    <vs-checkbox
                        v-model="cgv"
                        v-validate="'required'"
                    > J'accepte les
                    </vs-checkbox>
                    <div class="ml-1">
                      <router-link class="mb-4" target="_blank" to="/cgu">conditions générales d'utilisation</router-link>
                    </div>
                  </div>
                  <div class="actions">
                    <vs-button
                        :disabled="formIsValid() || !cgv"
                        class="ml-auto px-4 tw-w-full md:tw-w-auto mt-4"
                        @click="checkForm"
                    >Enregistrer
                    </vs-button>
                  </div>
                </div>
                <div v-if="expiry" class="p-8">
                  <div class="vx-card__title">
                    <h4 class="mb-4">Votre lien de réinitialisation est expiré</h4>
                    <p class="mt-4 mb-8">Vous pouvez redemander un lien de réinitialisation de mot de
                      passe</p>
                    <vs-button
                        class="px-4 tw-w-full md:tw-w-auto"
                        to="/forgot-password/"
                        type="border"
                    >Mot de passe oublié
                    </vs-button>
                  </div>
                </div>
                <div v-if="confirmuser" class="p-8">
                  <div class="vx-card__title">
                    <h4 class="mb-4">Votre compte est validé !</h4>
                    <p class="mt-4 mb-8">Vous pouvez à présent vous authentifier</p>
                    <vs-button
                        class="px-4 tw-w-full md:tw-w-auto"
                        to="/connexion"
                        type="border"
                    >S'identifier
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
        <footer class="the-footer flex-wrap justify-between">
          <p>
            <span>COPYRIGHT &copy;</span>
            <span>{{ new Date().getFullYear() }} </span>
            <a href="https://www.happytal.com" rel="nofollow" target="_blank">happytal</a>
            <span class="tw-hidden sm:tw-inline-block">, Tous droits réservés</span>
          </p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Base64 } from 'js-base64'
import TheNavbarVerticalPublic from '@/components/navbar/TheNavbarVerticalPublic.vue'
import appsettings from '@/appsettings'
import Regexes from '@/constants/regexes'
import Routes from '@/constants/routes'
import VuePassword from 'vue-password'

export default {
  components: {
    TheNavbarVerticalPublic,
    VuePassword
  },
  data() {
    return {
      first_name: '',
      expiry: false,
      passwordrecovery: false,
      confirmuser: false,
      setpassword: false,
      password: '',
      password2: '',
      token: '',
      cgv: false,
      hasMinuscule: false,
      hasMajuscule: false,
      hasSpecial: false,
      hasNumber: false,
      hasMore: false,
      placeholders: {
        typePassword: 'Saisissez votre mot de passe',
        confirmNewPassword: 'Confirmez votre nouveau mot de passe',
        typeNewPassword: 'Saisissez votre nouveau mot de passe'
      },
      popinHelp: false,
      tel: '',
      status: '',
    }
  },
  computed: {
    title() {
      if (this.setpassword || this.confirmuser) {
        return 'Activation de compte'
      }
      if (this.passwordrecovery) {
        return 'Réinitialisation du mot de passe'
      }
      return ''
    },
    validateForm() {
      if (this.setpassword) {
        return !this.errors.any() && this.password !== '' && this.password2 !== '' && this.password2 == this.password && this.cgv
      } else {
        return !this.errors.any() && this.password !== '' && this.password2 !== '' && this.password2 == this.password
      }
    },
    confirmationValid () {
      return (this.password == this.password2) && (this.password != '')
    }
  },
  methods: {
    checkConditionsClass() {
      if (this.password && this.password.length > 0) {
        if (this.hasMore && this.hasMinuscule && this.hasMajuscule && this.hasSpecial && this.hasNumber) {
          return 'valid'
        } else {
          return 'invalid'
        }
      } else {
        return ''
      }
    },
    checkConditionsValidation() {
      if (this.password && this.password.length > 0) {
        if (this.hasMore && this.hasMinuscule && this.hasMajuscule && this.hasSpecial && this.hasNumber) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    formIsValid() {
      if (this.checkConditionsValidation() && this.checkConditionsConfirmationValidation()) {
        return false;
      } else {
        return true;
      }
    },
    checkConditionsClassConfirmation() {
      if (this.password2 && this.password2.length > 0) {
        if (this.hasMore && this.hasMinuscule && this.hasMajuscule && this.hasSpecial && this.hasNumber) {
          if (this.password === this.password2) {
            return 'valid'
          } else {
            return 'invalid'
          }
        } else {
          return 'invalid'
        }
      } else {
        return ''
      }
    },
    checkConditionsConfirmationValidation() {
      if (this.password2 && this.password2.length > 0) {
        if (this.hasMore && this.hasMinuscule && this.hasMajuscule && this.hasSpecial && this.hasNumber) {
          if (this.password === this.password2) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    checkConditions() {
      this.hasMore = this.password.length >= 8
      this.hasMinuscule = Regexes.Minuscule.test(this.password)
      this.hasMajuscule = Regexes.Majuscule.test(this.password)
      this.hasSpecial = this.password && !Regexes.AlphaNumeric.test(this.password)
      this.hasNumber = Regexes.Number.test(this.password)
    },
    async checkForm() {
      this.$vs.loading()
      if (!this.password || !this.password2) {
        this.$vs.loading.close()
        this.$vs.notify({
          text: 'Mot de passe invalides',
          color: 'alert'
        })
      } else if (this.password != this.password2) {
        this.$vs.loading.close()
        this.$vs.notify({
          text: 'Les mots de passe ne correspondent pas',
          color: 'alert'
        })
      } else {
        try {
          await axios.post(appsettings.VUE_APP_USER_API + '/' + this.$route.query.id + '/reset-password', {
            'token': this.token,
            'newPassword': Base64.encode(this.password)
          })
          this.$vs.loading.close()
          this.$vs.notify({
            text: 'Votre mot de passe a bien été modifié.',
            color: 'success'
          })
          this.$router.push('/residents')
        } catch (error) {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Erreur',
            text: 'Une erreur est survenue, merci de re-essayer',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
        this.email = ''
      }
    },
  },
  beforeCreate: function () {
    const userId = this.$route.query.id
    this.$vs.loading()
    if (this.$route.query['password-reset'] === 'true') {
      axios.post(appsettings.VUE_APP_USER_API + '/' + userId + '/confirm', {
        'token': this.$route.query.token,
        'passwordReset': true
      })
      .then((response) => {
        this.passwordrecovery = true
        this.token = response.data.resetPasswordToken
        this.$vs.loading.close()
      })
      .catch(error => {
        this.$vs.loading.close()
        this.$router.push({
          name: Routes.ForgotPasswordExpired,
          params: {
            user_id: userId
          }
        })
      })
    } else {
      axios.post(appsettings.VUE_APP_USER_API + '/' + userId + '/confirm', {
        'token': this.$route.query.token
      })
      .then((response) => {
        if (response && response.data && response.data.resetPasswordToken) {
          this.setpassword = true
          this.token = response.data.resetPasswordToken
          this.$vs.loading.close()
        } else {
          this.confirmuser = true
          this.$vs.loading.close()
        }
      })
      .catch(error => {
        this.$vs.loading.close()
        this.$router.push({
          name: Routes.UserAccountExpired,
          params: {
            user_id: userId
          }
        })
      })
    }
  },
}
</script>
<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';
$default-col-width: 620px;

.main-content {
  width: 1300px;
  max-width: 100%;
  padding: 120px 3.4rem 0;

  @media (max-width: map-get($breakpoints, sm)) {
    padding: 120px 2.4rem 0;
  }
}

.vx-card {
  background: none;
  border-radius: 0;
  box-shadow: none;
  transition: none;
  background: #F8F8F8;
}

.vx-col.left {
  background: #F8F8F8;
  flex: 1;
  width: $default-col-width;
  max-width: 100%;

  @media (max-width: map-get($breakpoints, md)) {
    flex: 0 0 $default-col-width;
  }
}

.vx-col.right {
  background: #fff;
  border-radius: .5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  width: $default-col-width;
  max-width: 100%;

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}

.trait-vertical {
  margin: 5px 0px 0px 0px;
  border-left: 2px solid rgba(var(--vs-primary), 1);
  padding-left: 20px;
  position: absolute;
  left: 15px;
  height: 140px;
  z-index: 20;

  @media (max-width: map-get($breakpoints, sm)) {
    height: 185px;
  }

  &.recovery {
    height: 185px;

    @media (max-width: map-get($breakpoints, sm)) {
      height: 240px;
    }
  }
}

.step-list {
  margin-top: 20px;
  position: relative;

  .step {
    display: flex;
    align-items: flex-start;
    margin: 20px 0px 0px 0px;

    .step-bullet {
      display: inline-block;
      flex-shrink: 0;
      background-color: rgba(var(--vs-primary), 1);
      width: 32px;
      height: 32px;
      border-radius: 16px;
      color: #fff;
      text-align: center;
      padding: 6px;
      font-size: 14px;
      z-index: 30;
    }

    .step-text {
      margin: 5px 0px 0px 10px;
      font-size: 14px;
    }
  }
}

.m-t-30 {
  margin-top: 40px !important;
}

.vs-alert span, .vs-alert h4 {
  font-size: 13px;
}

.vs-alert {
  padding: 8px 10px;
}

.vs-alert span {
  font-size: 13px;
}

.vx-row {
  background: #F8F8F8;
}

.vs-alert--title {
  font-size: .9rem;
  font-weight: 700;
  padding: 0px;
}

.logo-login img {
  max-width: 263px !important;
  width: 263px !important;
  margin: 65px auto;
}

footer {
  margin-top: 40px;

  p {
    margin: 0 auto;
    display: block;
    text-align: center;
  }

}

ul.specialchar {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 10px;

  @media (max-width: map-get($breakpoints, md)) {
   flex-wrap: wrap;
   width: 360px;
   max-width: 100%;
  }

  li {
    text-align: center;
    font-size: 12px;
    padding: 0 8px;

    @media (max-width: map-get($breakpoints, sm)) {
      width: calc(100% / 3);
      margin: .5rem 0;
    }
    @media (max-width: map-get($breakpoints, sm)) {
      width: 50%;
    }

    &.special-car {
      flex-shrink: 0;
    }

    span {
      font-size: 18px;
    }
  }
}

.infochar {
  margin: 10px 0px 0px 0px;
  font-weight: 600;
  font-size: 12px;
}

.m-t-10 {
  margin-top: 25px;
}

a {
  color: rgb(1, 207, 232);
}

.vs-input--input.hasValue + .vs-input--placeholder {
  display: none !important;
}

.invalid input {
  border-color: red !important;
}

.m-t-5 {
  margin-top: 5px;
}

li.validation-error {
  color: #EA5455;
}

li.valid {
  color: #4CAF50;
}

h5 {
  margin-bottom: 5px;
}

.VuePassword__Input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: .7rem !important;
  font-size: 1rem !important;
  border-radius: 5px;
}

.VuePassword__Toggle {
  cursor: pointer;
  background: transparent;
}

.valid .VuePassword__Input {
  border-color: green;
}

.invalid .VuePassword__Input {
  border-color: red;
}
.cgv {
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;

  a {
    color: rgba(var(--vs-primary), 1);
  }
  .con-slot-label {
    margin-left: .25rem;
  }
}
.relative{
  position: relative;
}
.position {
  margin-bottom: 10px;

  @media (min-width: 500px) {
      position: absolute;
      top: 13px;
      right: 10px;
      margin-bottom: 20px;
  }
}


</style>
